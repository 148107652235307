import type {
  ScoreboardResponse,
  ScoreboardResponseRelatedScheduleResponseRelatedPagedData,
} from '@on3/api';
import { getUserTimeZone } from '@on3/ui-lib/utils/date';
import { SidebarContainer } from 'components/UI/Layout/Containers/Sidebar/SidebarContainer/SidebarContainer';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';
import { isBefore, startOfDay } from 'date-fns';
import { slugify } from 'utils/slugify';

import styles from './ScheduleModule.module.scss';
import { ScheduleModuleItem } from './ScheduleModuleItem';

const VISIBLE_GAMES = 10;

export const ScheduleModule = ({
  list,
  relatedModel,
}: ScoreboardResponseRelatedScheduleResponseRelatedPagedData) => {
  const showFullSchedule =
    !!relatedModel?.organization?.slug &&
    !!relatedModel?.sport?.name &&
    !!relatedModel.year;
  const sluggedSport = slugify(relatedModel?.sport?.name || 'football');
  const urlSchedules = `/college/${relatedModel?.organization?.slug}/${sluggedSport}/${relatedModel?.year}/schedule/`;
  const userTimeZone = getUserTimeZone();

  let gameList = list;

  // We only want to show x number of games for basketball
  if (relatedModel?.sport?.key === 2) {
    const today = startOfDay(new Date());

    const pastGames: ScoreboardResponse[] = [];
    const futureGames: ScoreboardResponse[] = [];

    gameList?.forEach((game) => {
      const gameDate = new Date((game?.startDateUtc || 0) * 1000);

      if (isBefore(gameDate, today)) {
        pastGames.push({ ...game });
      } else {
        futureGames.push({ ...game });
      }
    });

    // Below will insure we always show x number of games on the home module.
    if (pastGames.length < 5) {
      const sliceNumber = VISIBLE_GAMES - pastGames.length;

      gameList = [...pastGames, ...futureGames.slice(0, sliceNumber)];
    } else if (futureGames.length < 5) {
      const sliceNumber = VISIBLE_GAMES - futureGames.length;

      gameList = [...pastGames.slice(-sliceNumber), ...futureGames];
    } else {
      gameList = [...pastGames.slice(-5), ...futureGames.slice(0, 5)];
    }
  }

  return (
    <SidebarContainer
      className={styles.container}
      title={`${relatedModel?.sport?.name} Schedule`}
    >
      <ol className={styles.schedulewrapper}>
        {gameList?.map((game) => (
          <ScheduleModuleItem
            game={game}
            key={game?.key}
            sport={relatedModel?.sport?.name || 'football'}
          />
        ))}
      </ol>
      <div className={styles.linkTimeContainer}>
        {showFullSchedule && (
          <TextLink
            className={styles.schedulesLink}
            href={urlSchedules}
            underline="none"
            variant="subtitle1"
          >
            Full Schedule
          </TextLink>
        )}
        {userTimeZone && (
          <Text className={styles.timeZone} variant="subtitle1">
            All Times {userTimeZone}
          </Text>
        )}
      </div>
    </SidebarContainer>
  );
};
