import type { HeadlineResponse, HeadlineResponsePagedData } from '@on3/api';
import { useSite } from '@on3/ui-lib/src/contexts/SiteProvider';
import { ArticleCondensed } from 'components/Headlines/ArticleCondensed/ArticleCondensed';
import { ArticleHeadliner } from 'components/Headlines/ArticleHeadliner/ArticleHeadliner';
import { SidebarTeamContainer } from 'components/UI/Layout/Containers/Sidebar/TeamContainer/SidebarTeamContainer';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';

import styles from './CondensedList.module.scss';

interface ICondensedListProps extends HeadlineResponsePagedData {
  secondaryList?: HeadlineResponse[];
}

export const CondensedList = ({
  list = [],
  secondaryList = [],
}: ICondensedListProps) => {
  const { currentSite } = useSite();

  return (
    <SidebarTeamContainer>
      {list && list?.length > 0 && (
        <div className={styles.wrapper}>
          <TextLink
            className={styles.label}
            color="primary"
            href={
              currentSite?.isTeam
                ? `/college/${currentSite?.slug}/`
                : `/teams/${currentSite?.slug}/`
            }
            variant="subtitle1"
          >
            {currentSite?.organization?.mascot} sports
          </TextLink>
          <div className={styles.headliner}>
            <ArticleHeadliner {...list[0]} />
          </div>
          {list.slice(1, 5).map((item, index) => {
            return <ArticleCondensed {...item} key={index} />;
          })}
        </div>
      )}
      {secondaryList?.length > 0 && (
        <div className={styles.wrapper}>
          <Text className={styles.label} color="primary" variant="subtitle1">
            {currentSite?.organization?.mascot} Recruiting
          </Text>
          <div className={styles.headliner}>
            <ArticleHeadliner {...secondaryList[0]} />
          </div>
          {secondaryList.slice(1, 5).map((item, index) => {
            return <ArticleCondensed {...item} key={index} />;
          })}
        </div>
      )}
    </SidebarTeamContainer>
  );
};
